import React, { useState } from "react"
import PropTypes from "prop-types"
import PrimaryLayout from "@src/layouts/primary"
import { UserContext } from "@src/context/user-context"
import PageHelmet from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveSection from "@src/components/container-responsive-section"
import Form, { FormActions } from "@src/components/form"
import FormFieldEmailAddress from "@src/components/form-field-email"
import FormFieldVerificationCode from "@src/components/form-field-verification-code"
import Button from "@src/components/core-button"
import Link from "@src/components/core-link"
import Spinner from "@src/components/core-spinner"
const AccountVerificationPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const [email, setEmail] = useState(params.get("email"))
  const [success, setSuccess] = useState()
  const [verifying, setVerifying] = useState(false)
  const [formMessage, setFormMessage] = useState()
  return (
    <PrimaryLayout>
      <PageHelmet title={normalizeTitle("Verification")} />
      <UserContext.Consumer>
        {({ verify }) => {
          const handleSubmit = ({ email, code }) => {
            setVerifying(true)
            verify(email, code)
              .then(() => {
                setVerifying(false)
                setSuccess(true)
                setFormMessage()
              })
              .catch(error => {
                setVerifying(false)
                setSuccess(false)
                setFormMessage(error)
              })
          }
          return success ? (
            <ResponsiveSection variant="card">
              <h1>Account verfied!</h1>
              <p>
                <Link to="/account/login/">Click here to log in now</Link>
              </p>
            </ResponsiveSection>
          ) : (
            <ResponsiveSection variant="card">
              <h1>Account verification</h1>
              <Form
                name="user-verification"
                onSubmit={handleSubmit}
                variant="blue"
              >
                <FormFieldEmailAddress
                  id="email"
                  name="email"
                  placeholder="Enter email address for verification"
                  label="Email address"
                  defaultValue={email}
                  onChange={setEmail}
                  validation={{
                    required:
                      "We'll need your email address to verify the account",
                  }}
                />
                <FormFieldVerificationCode
                  id="code"
                  name="code"
                  placeholder="Enter your 6-digit verification code"
                  label="Verification code"
                  validation={{
                    required:
                      "Please check your email for the verification code",
                  }}
                />
                <FormActions>
                  {formMessage && <p className="form-error">{formMessage}</p>}
                  {verifying ? (
                    <Button variant="rounded" disabled>
                      Verifying <Spinner color="white" size="sm" />
                    </Button>
                  ) : (
                    <Button variant="rounded" type="submit">
                      Verify Account
                    </Button>
                  )}
                  <p>
                    No verification code?{" "}
                    <Link
                      to={`/account/verification-resend/${
                        email ? "?email=" + encodeURIComponent(email) : ""
                      }`}
                    >
                      Get a new code
                    </Link>
                  </p>
                  <p>
                    Haven&apos;t signed up yet?{" "}
                    <Link to="/account/signup/">Create an account</Link>
                  </p>
                </FormActions>
              </Form>
            </ResponsiveSection>
          )
        }}
      </UserContext.Consumer>
    </PrimaryLayout>
  )
}
AccountVerificationPage.propTypes = {
  location: PropTypes.object,
}
export default AccountVerificationPage
